import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

// import interfaces
import { IKEPDataProps } from './../../props/data';

// import components
import KEPList from './../../components/KEP/KEPList';
import { IColumn, Stack } from '@fluentui/react';

// import fabric ui


// local interfaces
interface IKEPPageProps { }
interface IKEPPageState { }

export default class KEPPage extends React.Component<IKEPPageProps, IKEPPageState> {
    private listColumns: IColumn[] = [
        {
            key: 'KEPName',
            name: 'Nama KEP',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 200,
            data: 'string',
            isPadded: true
        },
        {
            key: 'city',
            name: 'Kota',
            fieldName: 'city',
            minWidth: 0,
            maxWidth: 250,
            data: 'string',
            isPadded: true
        },
        {
            key: 'address',
            name: 'Alamat',
            fieldName: 'address',
            minWidth: 0,
            data: 'string',
            isPadded: true
        }
    ];

    constructor(props: IKEPPageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack styles={{ root: { padding: 20 } }}>
                <Stack.Item>
                    <h2>KEP</h2>
                </Stack.Item>
                <Stack.Item>
                    <KEPList
                        listColumns={this.listColumns}
                        includeCommandBar={true}
                        hasActionButton={true}
                        searchBy={["name", "city", "days"]}
                        sortable={["name", "city", "day"]}
                        filterable={["name", "city", "day"]} />
                </Stack.Item>
            </Stack>
        );
    }
}
