import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { IIntakeDataProps } from './../../props/data';
import { IListPanelClosed, IFilterProps, IFilterOptionProps } from './../../props/general';

// import services
import IntakeService from './../../services/intake';
import GeneralService from './../../services/general';

// import components
import IntakePropsPanel from './intakePropsPanel';
import DeleteDataPanel, { IDeleteDataPanelOnDeleteReturn } from './../panels/deleteData';
import FilterDataPanel from './../panels/filterData';
import { Selection, Text, IColumn, ICommandBarItemProps, MessageBarType, IContextualMenuProps, ContextualMenuItemType, SearchBox, IconButton, Stack, CommandBar, MessageBar, Spinner, SpinnerSize, ContextualMenu, DetailsList, SelectionMode, DetailsListLayoutMode, Icon } from '@fluentui/react';

// import fabric ui


// local interfaces
interface IDeletedDataProps extends IIntakeDataProps {
    status: string;
    message?: string;
}

interface IIntakeListProps {
    listColumns: IColumn[];
    includeCommandBar: boolean;
    hasActionButton: boolean;
    filterable?: string[];
    sortable?: string[];
    searchBy?: string[];
}

interface IIntakeListState {
    commandBar: {
        leftItems?: ICommandBarItemProps[];
        rightItems?: ICommandBarItemProps[];
    };
    messageBar?: { text: string; type: MessageBarType };
    data: IIntakeDataProps[];
    deletedData?: IDeletedDataProps[];
    raw: IIntakeDataProps[];
    searchKeyword: string;
    selectedData?: IIntakeDataProps[];
    loading?: boolean;
    loadingDeletion?: boolean;
    listColumns: IColumn[];
    components: {
        KEPPropsPanelOpened?: boolean;
        DeletePanelOpened?: boolean;
        FilterPanelOpened?: boolean;
    };
    selectedHeader?: IColumn;
    columnHeaderContextMenu?: IContextualMenuProps;
    filters: IFilterProps[];
    sortBy?: {
        fieldName: string;
        type: 'asc' | 'desc';
    };
}

export default class KEPList extends React.Component<IIntakeListProps, IIntakeListState> {
    private intakeService: IntakeService = new IntakeService();
    private _selection = new Selection({
        onSelectionChanged: () => { this._onSelectionChanged(this._selection.getSelection() as IIntakeDataProps[]) }
    });

    constructor(props: IIntakeListProps) {
        super(props);

        this.state = {
            commandBar: {},
            data: [],
            raw: [],
            loading: true,
            listColumns: [],
            components: {},
            filters: [],
            searchKeyword: ""
        }
    }

    private _onSelectionChanged = (selectedData: IIntakeDataProps[]) => {
        let leftItems = this.state.commandBar.leftItems;
        let deleteClassItem = leftItems ? leftItems.find((item) => { return item.key === 'deleteIntake' }) : undefined;
        if (deleteClassItem) {
            deleteClassItem.disabled = !selectedData || (selectedData && selectedData.length < 1);
        }

        this.setState({
            commandBar: { leftItems }
        });
    }

    private _onColumnHeaderClicked = (sortable: boolean, filterable: boolean, ev?: any, column?: IColumn) => {
        let items = [];

        if (filterable) {
            items.push({
                key: 'filterBy',
                iconProps: { iconName: 'Filter' },
                text: 'Filter',
                title: 'Filter',
                onClick: () => { this.setState({ components: { FilterPanelOpened: true } }); }
            });
        }
        if (sortable) {
            items.push({
                key: 'sortSection',
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                    topDivider: true,
                    title: 'Urutkan',
                    items: [
                        {
                            key: 'asc',
                            text: 'A to Z (Ascending)',
                            iconProps: { iconName: "SortUp" },
                            onClick: () => { this._onSort('asc'); }
                        },
                        {
                            key: 'desc',
                            text: 'Z to A (Descending)',
                            iconProps: { iconName: "SortDown" },
                            onClick: () => { this._onSort('desc'); }
                        }
                    ]
                }
            })
        };

        this.setState({
            selectedHeader: column,
            columnHeaderContextMenu: {
                items,
                target: ev.currentTarget as HTMLElement,
                gapSpace: 10,
                onDismiss: () => { this.setState({ columnHeaderContextMenu: undefined }) }
            }
        })
    }

    public async componentDidMount() {
        let messageBar: { text: string; type: MessageBarType } | undefined = undefined;
        let leftItems: ICommandBarItemProps[] = [
            {
                key: 'searchIntake',
                onRender: () => {
                    return (
                        <SearchBox className={kepInternalStyles.searchBox} placeholder="Cari Tahun Pelajaran" onChange={this._onSearch} />
                    );
                }
            }
        ]

        if (GeneralService.isCurrentUserAdmin() || GeneralService.isCurrentUserSecretary()) {
            leftItems = [...leftItems, ...[
                {
                    key: 'newIntak',
                    text: 'Tambah Tahun Pelajaran Baru',
                    iconProps: { iconName: 'Add' },
                    onClick: () => {
                        this.setState({ selectedData: undefined, components: { KEPPropsPanelOpened: true } });
                    }
                },
                {
                    key: 'deleteIntake',
                    text: 'Hapus Tahun Pelajaran',
                    iconProps: { iconName: 'Delete' },
                    disabled: true,
                    onClick: () => { this.setState({ deletedData: undefined, components: { DeletePanelOpened: true } }); }
                }
            ]
            ];
        }

        let rawData: any = [];
        try {
            rawData = await this.intakeService.getAll();
        } catch (e: any) {
            messageBar = {
                text: "Maaf, ada masalah saat mengambil data tahun pelarajan. Tolong refresh halaman dan coba beberapa saat lagi.",
                type: MessageBarType.error
            }
        }
        let listColumns: IColumn[] = this.props.listColumns;
        let { sortable, filterable } = this.props;
        listColumns = listColumns.map((column) => {
            let isSortable: boolean = (sortable && sortable.indexOf(column.fieldName || column.key) > -1) || false;
            let isFilterable: boolean = (filterable && filterable.indexOf(column.fieldName || column.key) > -1) || false;
            if (isSortable || isFilterable) {
                column.iconName = "ChevronDown";
                column.styles = { iconClassName: { marginRight: 5 } };
                column.onColumnClick = (ev, column) => { this._onColumnHeaderClicked(isSortable, isFilterable, ev, column) };
            }
            return column;
        });

        if (this.props.hasActionButton && (GeneralService.isCurrentUserAdmin() || GeneralService.isCurrentUserSecretary())) {
            listColumns.splice(1, 0, {
                key: 'actionButtons',
                name: '',
                minWidth: 20,
                maxWidth: 20,
                isIconOnly: true,
                className: kepInternalStyles.listActionButtons,
                onRender: (item?: any) => {
                    return (
                        <IconButton
                            onMenuClick={() => {
                                this.setState({
                                    selectedData: [item as IIntakeDataProps]
                                });
                            }}
                            menuProps={{
                                items: [
                                    {
                                        key: 'updateKEP',
                                        text: 'Ubah data',
                                        iconProps: { iconName: 'Edit' },
                                        onClick: () => { this.setState({ components: { KEPPropsPanelOpened: true } }); }
                                    }
                                ]
                            }}
                            title="Tombol aksi"
                            ariaLabel="Tombol aksi" />
                    );
                }
            });
        }

        this.setState({
            commandBar: { leftItems },
            listColumns,
            raw: rawData,
            data: rawData,
            loading: false,
            messageBar
        });
    }

    private async _onRefreshData() {
        this.setState({ loading: true });
        try {
            let data = await this.intakeService.getAll();
            this.setState({ data, loading: false });
        } catch (error) {

        }
    }

    private _onPanelClosed = async (props?: IListPanelClosed): Promise<void> => {
        this.setState({ components: {} });
        if (props && props.refreshData) { this._onRefreshData(); }
        if (props && props.messageBar) { this.setState({ messageBar: props.messageBar }); }
    }

    private processData() {
        let { filters, sortBy, raw, searchKeyword, data } = this.state;
        let { searchBy } = this.props;

        // filter
        data = GeneralService.filterData(raw, filters);

        // search
        searchKeyword = searchKeyword.trim();
        if (searchKeyword !== "" && searchBy) {
            data = GeneralService.searchData(data, searchBy, searchKeyword);
        }

        // sort
        if (sortBy) {
            data = GeneralService.sortData(data, sortBy.fieldName, sortBy.type);
        }

        this.setState({ data, loading: false });
    }

    private _onFilterApplied = (fieldName: string, selectedOptions: IFilterOptionProps[]) => {
        this.setState({ loading: true, components: {} });

        let { raw, filters, selectedHeader } = this.state;
        let relatedFilter = filters.find((filter) => { return filter.fieldName === fieldName; });
        if (relatedFilter) {
            if (selectedOptions.length > 0) {
                relatedFilter.selectedOptions = selectedOptions;
            } else {
                let relatedFilterIndex = filters.findIndex((filter) => { return filter.fieldName === fieldName; });
                filters.splice(relatedFilterIndex, 1);
            }
        } else if (selectedOptions.length > 0) {
            filters.push({
                fieldName,
                text: selectedHeader ? selectedHeader.name : "",
                selectedOptions
            });
        }

        this.setState({ filters }, () => { this.processData(); });
    }

    private _onSearch = (evt?: any, key?: string) => {
        this.setState({
            loading: true,
            components: {},
            searchKeyword: key ? key.trim() : ""
        }, () => { this.processData(); });
    }

    private _onSort = (type: "asc" | "desc") => {
        let { selectedHeader } = this.state;
        if (selectedHeader) {
            this.setState({
                loading: true,
                components: {},
                sortBy: { fieldName: selectedHeader.fieldName || selectedHeader.key, type }
            }, () => { this.processData(); });
        }
    }

    private _onDelete = async (data: any): Promise<IDeleteDataPanelOnDeleteReturn> => {
        try {
            let deletedData: IDeletedDataProps[] = (data as IIntakeDataProps[]).map((d) => {
                return {
                    ...d,
                    status: 'inprogress'
                }
            });

            this.setState({ deletedData }, async () => {
                for (var ctr = -0; ctr < deletedData.length; ctr++) {
                    let d = deletedData[ctr];
                    try {
                        await this.intakeService.delete(d as IIntakeDataProps);
                        d.status = 'completed';
                        this.setState({ deletedData });
                    } catch (e: any) {
                        d.status = 'error';
                        d.message = e;
                        this.setState({ deletedData });
                    }
                }
            });

            return {
                success: true,
                message: "",
                delay: 5000
            }
        } catch (e: any) {
            return {
                success: false,
                message: "Maaf, sistem mengalami masalah saat menghapus data. Pesan error: " + e.toString()
            }
        }
    }

    public render() {
        let { selectedHeader, filters } = this.state;
        return (
            <Stack tokens={{ childrenGap: 10 }}>
                {
                    this.props.includeCommandBar ? (
                        <Stack.Item>
                            <CommandBar
                                items={this.state.commandBar.leftItems || []}
                                farItems={this.state.commandBar.rightItems || []}
                                ariaLabel="Gunakan panah kiri dan kanan untuk berpindah tombol"
                                className={kepInternalStyles.commandBar}
                                styles={{ root: { padding: 0 } }} />
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => { this.setState({ messageBar: undefined }) }}>
                                {this.state.messageBar.text}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                <Stack.Item>
                    {
                        this.state.loading ? (
                            <Spinner label="Memuat data KEP ..." labelPosition="bottom" size={SpinnerSize.large} />
                        ) : null
                    }
                    {this.state.columnHeaderContextMenu ? <ContextualMenu {...this.state.columnHeaderContextMenu} /> : null}
                    {
                        !this.state.loading ? (
                            <DetailsList
                                selection={this._selection}
                                items={this.state.data}
                                compact={false}
                                columns={this.state.listColumns}
                                selectionMode={SelectionMode.multiple}
                                setKey="none"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true} />
                        ) : null
                    }
                    {!this.state.loading && this.state.data.length < 1 ? <Text style={{ padding: 10 }}>Tahun pelajaran tidak dapat ditemukan. Tekan tombol "Tambah Tahun Pelajaran Baru" untuk membuat tahun pelajaran baru.</Text> : null}
                </Stack.Item>
                <Stack.Item>
                    <IntakePropsPanel data={this.state.selectedData ? this.state.selectedData[0] : undefined} onPanelClosed={this._onPanelClosed} isOpen={this.state.components.KEPPropsPanelOpened || false} />
                </Stack.Item>
                <Stack.Item>
                    <DeleteDataPanel data={this.state.deletedData || this._selection.getSelection()}
                        columns={[
                            { key: "name", fieldName: "name", name: "Nama", minWidth: 0 },
                            {
                                key: "status", fieldName: "status", name: "Status", minWidth: 0, onRender: (item) => {
                                    if (item.status === 'inprogress') { return <Spinner size={SpinnerSize.small} label="Menghapus ..." labelPosition={"right"} /> }
                                    else if (item.status === 'completed') { return <Text><Icon iconName="CheckMark" /> Berhasil</Text> }
                                    else if (item.status === 'error') { return <Text><Icon iconName="Error" /> Gagal</Text> }
                                }
                            }
                        ]}
                        isOpen={this.state.components.DeletePanelOpened || false}
                        onPanelClosed={() => this._onPanelClosed({ refreshData: true })}
                        onDelete={this._onDelete} />
                </Stack.Item>
                <Stack.Item>
                    <FilterDataPanel isOpen={this.state.components.FilterPanelOpened || false}
                        data={this.state.raw}
                        fieldName={selectedHeader ? selectedHeader.fieldName || selectedHeader.key : ""}
                        panelHeader={selectedHeader ? `Filter '${selectedHeader.name}'` : ""}
                        onPanelClosed={this._onPanelClosed}
                        onFilterApplied={this._onFilterApplied}
                        currentFilters={filters} />
                </Stack.Item>
            </Stack>
        );
    }
}
