import { Stack, Label, Text } from '@fluentui/react';
import React from 'react';

// import fabric ui components

interface IFieldHeaderProps {
    title?: string;
    content?: string;
    required?: boolean;
    help?: {
        icon: string;
        title: string;
        content: string;
    }
}

export default class FieldHeader extends React.Component<IFieldHeaderProps, IFieldHeaderProps> {
    constructor(props: IFieldHeaderProps) {
        super(props);

        this.state = this.props;
    }

    public componentWillReceiveProps(props: IFieldHeaderProps) {
        this.setState({ ...this.props });
    }

    public render() {
        let { title, content, help, required } = this.state;
        return (
            <Stack>
                <Stack tokens={{ childrenGap: 5 }}>
                    {title ? <Stack.Item><Label required={required} style={{ padding: 0 }}>{title}</Label></Stack.Item> : null}
                    {content ? <Stack.Item><Text>{content}</Text></Stack.Item> : null}
                </Stack>
            </Stack>
        );
    }
}