import React from 'react';
import moment from 'moment';

// import components
import ScheduleList, { IAllDataProps } from './../../components/schedule/scheduleList';

// import service
import KEPService from './../../services/kep';
import ClassService from './../../services/class';
import ModuleService from './../../services/module';
import LessonService from './../../services/lesson';
import EventService from './../../services/event';
import UserService from './../../services/user';
import RandomiseService from './../../services/randomise';
import IntakeService from './../../services/intake';
import GeneralService from './../../services/general';
import ScheduleService from './../../services/schedule';

// import interfaces
import { ISchedulePreviewProps, IIntakeDataProps, IKEPDataProps, IClassDataProps, ILessonDataProps } from '../../props/data';
import { Text, MessageBarType, Stack, IDropdownOption, ITag, Spinner, SpinnerSize, MessageBar, Label, TagPicker, Dropdown, DatePicker } from '@fluentui/react';
import { Link } from 'react-router-dom';

// import fabric ui

// local interfaces
interface IRandomisePageProps { }
interface IRandomisePageState {
    loaded?: boolean;
    scheduling: boolean;
    schedules?: ISchedulePreviewProps[];
    selected: {
        KEP?: string;
        intake?: string;
        startDate?: string;
        hour: string;
        minute: string;
        day?: string;
    }
    data: IAllDataProps;
    searchingSchedule?: boolean;
    scheduleExist?: boolean;
    messageBar?: { content: JSX.Element; type: MessageBarType };
    showSchedules?: boolean;
    retrieveIntakesData?: boolean;
}

export default class RandomisePage extends React.Component<IRandomisePageProps, IRandomisePageState> {
    private kepService: KEPService = new KEPService();
    private classService: ClassService = new ClassService();
    private moduleService: ModuleService = new ModuleService();
    private lessonService: LessonService = new LessonService();
    private userService: UserService = new UserService();
    private eventService: EventService = new EventService();
    private intakeService: IntakeService = new IntakeService();
    private randomiseService: RandomiseService = new RandomiseService();
    private scheduleService: ScheduleService = new ScheduleService();

    constructor(props: IRandomisePageProps) {
        super(props);
        this.state = {
            scheduling: false,
            data: { KEP: [], classes: [], modules: [], lessons: [], events: [], teachers: [], intakes: [] },
            selected: { hour: '', minute: '' }
        }
    }

    public async componentWillMount() {
        let KEP = await this.kepService.getAll();
        let classes = await this.classService.getAll();
        let modules = await this.moduleService.getAll();
        let lessons = await this.lessonService.getAll();
        let teachers = await this.userService.getAll("Guru");
        let intakes = await this.intakeService.getAll(true);

        // check if query string is defined
        let kepId = GeneralService.getParameterByName("KEP");
        let startDate = GeneralService.getParameterByName("start");
        let endDate = GeneralService.getParameterByName("end");
        let selected: any = {
            startDate,
            endDate
        }

        if (kepId) {
            let selectedKEP = KEP.find((kep) => { return kep.id === kepId; });
            if (selectedKEP) {
                selected.KEP = { key: selectedKEP.id, name: selectedKEP.name };
            }
        }

        this.setState({
            data: {
                KEP: JSON.parse(JSON.stringify(KEP)),
                classes: JSON.parse(JSON.stringify(classes)),
                modules: JSON.parse(JSON.stringify(modules)),
                lessons: JSON.parse(JSON.stringify(lessons)),
                events: [],
                teachers: JSON.parse(JSON.stringify(teachers)),
                intakes: JSON.parse(JSON.stringify(intakes))
            },
            selected,
            loaded: true
        }, () => {
            this.generateSchedule();
        })
    }

    private _onFormatDate(date: Date | undefined) {
        return date ? moment(date).format("DD/MM/YYYY") : "";
    }

    private generateSchedule = async () => {
        const daysOfWeek = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
        let { selected, data } = this.state;

        let scheduleExist = false;
        if (selected.KEP && selected.intake) {
            this.setState({ searchingSchedule: true, showSchedules: false });
            let selectedKEPData = this.getKEPData(selected.KEP);
            let selectedIntakeData = this.getIntakeData(selected.intake);
            let existingSchedules = await this.scheduleService.getIntakeByKEPId(selected.KEP, selected.intake);
            scheduleExist = existingSchedules.length > 0;
            if (scheduleExist) {
                let scheduleStartDate = existingSchedules[0].date;
                let startDayIndex = moment(scheduleStartDate).isoWeekday();
                selected.day = daysOfWeek[startDayIndex];
                selected.startDate = scheduleStartDate;
            }
            this.setState({
                scheduleExist,
                searchingSchedule: false,
                showSchedules: false,
                selected,
                messageBar: scheduleExist ? {
                    content: (
                        <Stack verticalAlign={"center"} styles={{ root: { height: '100%' } }}>
                            <Text>Jadwal KEP "{selectedKEPData?.name}" untuk tahun pelajaran "{selectedIntakeData?.name}" sudah tersedia. Tekan <Link to={`/jadwal?kep=${selectedKEPData?.id}&intake=${selectedIntakeData?.id}`}>link berikut</Link> untuk membuka jadwal.</Text>
                        </Stack>
                    ),
                    type: MessageBarType.warning
                } : undefined
            });
        }

        if (!scheduleExist && selected.KEP && selected.day && selected.intake && selected.startDate) {
            this.setState({ scheduling: true, showSchedules: true });
            let actualStartDate: string = selected.startDate;
            let selectedKEPData = this.getKEPData(selected.KEP);
            let selectedIntakeData = this.getIntakeData(selected.intake);
            let selectedStartDate = moment(selected.startDate).isoWeekday();
            let selectedDayIndex: number = daysOfWeek.findIndex((d) => { return d === selected.day; }) || -1;
            if (selectedStartDate <= selectedDayIndex && selectedDayIndex > -1) {
                actualStartDate = moment(actualStartDate).isoWeekday(selectedDayIndex).toISOString();
            } else if (selectedDayIndex > -1) {
                actualStartDate = moment(actualStartDate).add(1, 'weeks').isoWeekday(selectedDayIndex).toISOString();
            }

            const classes: IClassDataProps[] = [];
            const lessons: ILessonDataProps[] = [];
            const modules = data.modules.filter((mod) => {
                return selectedKEPData && mod.includedKEPsId.indexOf(selectedKEPData.id) > -1;
            });
            modules.forEach((mod) => {
                if (classes.findIndex((cl) => cl.id === mod.class.id) < 0) {
                    classes.push(mod.class);
                }
            });
            data.lessons.forEach((lesson) => {
                if (modules.findIndex((mod) => mod.id === lesson.moduleId) > -1) {
                    lessons.push(lesson);
                }
            });

            if (actualStartDate && selectedIntakeData && selectedKEPData) {
                let events = await this.eventService.getAll();
                this.randomiseService.setData({ events, lessons: lessons, teachers: data.teachers, classes: classes, modules: modules });
                this.randomiseService.setSelected({ KEP: selectedKEPData, intake: selectedIntakeData, startDate: actualStartDate, hour: selected.hour, minute: selected.minute });

                let schedules = this.randomiseService.generateSchedules();
                this.setState({
                    data: {
                        ...this.state.data,
                        classes,
                        modules,
                        lessons
                    }, schedules, scheduling: false, showSchedules: true,
                });
            } else {
                this.setState({ scheduling: false, showSchedules: true });
            }
        }
    }

    private _onStartDateSelected = (date: null | undefined | Date) => {
        let { selected } = this.state;
        selected.startDate = date ? moment(date).toISOString() : "";
        this.setState({ selected }, () => { this.generateSchedule() });
    }

    private _onIntakeSelected = async (evt?: any, option?: IDropdownOption) => {
        let { selected } = this.state;
        selected.intake = option ? option.key as string : undefined;
        if (selected.intake) {
            let intake = this.getIntakeData(selected.intake);
            selected.startDate = intake ? intake.startDate : selected.startDate;
            selected.KEP = undefined;

            // load kep data
            this.setState({ retrieveIntakesData: true, selected });
            const intakeService = new IntakeService();
            const schedules = await intakeService.schedules(selected.intake);
            let data = this.state.data;
            data.KEP = data.KEP.map((kep) => {
                const hasSchedule = schedules.findIndex((sch: any) => sch.KEPId === kep.id) > -1;
                return { ...kep, scheduleGenerated: hasSchedule };
            });
            this.setState({
                data: { ...data },
                retrieveIntakesData: false
            });
        } else {
            this.setState({ selected });
        }

    }

    private _onHourSelected = (e?: any, option?: IDropdownOption) => {
        let { selected } = this.state;
        selected.hour = option ? option.key as string : "18";
        this.setState({ selected }, () => { this.generateSchedule() });
    }

    private _onMinuteSelected = (e?: any, option?: IDropdownOption) => {
        let { selected } = this.state;
        selected.minute = option ? option.key as string : "00";
        this.setState({ selected }, () => { this.generateSchedule() });
    }

    private _onDaySelected = (e?: any, option?: IDropdownOption) => {
        let { selected } = this.state;
        selected.day = option ? option.key as string : undefined;
        this.setState({ selected }, () => { this.generateSchedule() });
    }

    private _onSelectedKEPChanged = (selectedItems?: ITag[]) => {
        let { selected } = this.state;
        selected.KEP = selectedItems && selectedItems[0] ? selectedItems[0].key as string : undefined;
        this.setState({ selected }, () => { this.generateSchedule() });
    }

    private getIntakeData = (intakeId: string): IIntakeDataProps | undefined => {
        let { intakes } = this.state.data;
        return intakes.find((intake) => { return intake.id === intakeId; });
    }

    private getKEPData = (kepId: string): IKEPDataProps | undefined => {
        let { KEP } = this.state.data;
        return KEP.find((kep) => { return kep.id === kepId; });
    }

    private _onScheduleSaved = () => {
        setTimeout(() => {
            window.location.href = `${window.location.origin}/jadwal?kep=${this.state.selected.KEP}&intake=${this.state.selected.intake}`
        }, 5000);

        this.setState({
            showSchedules: false,
            messageBar: {
                content: <Text>Jadwal berhasil disimpan. Harap tunggu sebentar, kami akan mengarahkan anda ke halaman jadwal beberapa saat lagi atau klik <Link to={`/jadwal?kep=${this.state.selected.KEP}&intake=${this.state.selected.intake}`}>link berikut</Link>.</Text>,
                type: MessageBarType.success
            }
        });
    }

    public render() {
        let selectedKEPData: IKEPDataProps | undefined = this.state.selected.KEP ? this.getKEPData(this.state.selected.KEP) : undefined;
        let selectedIntakeData: IIntakeDataProps | undefined = this.state.selected.intake ? this.getIntakeData(this.state.selected.intake) : undefined;

        return (
            <Stack styles={{ root: { padding: 20 } }}>
                <Stack.Item>
                    <h2>Pengacakan Jadwal</h2>
                </Stack.Item>
                {
                    !this.state.loaded ? (
                        <Spinner label="Mempersiapkan data ..." labelPosition="bottom" size={SpinnerSize.large} />
                    ) : null
                }
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => { this.setState({ messageBar: undefined }) }}>
                                {this.state.messageBar.content}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.loaded ? (
                        <Stack tokens={{ childrenGap: 15 }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack.Item grow={1} styles={{ root: { width: '50%' } }}>
                                    <Dropdown
                                        disabled={this.state.scheduling || this.state.searchingSchedule}
                                        label={"Pilih tahun pelajaran"}
                                        options={this.state.data.intakes ? this.state.data.intakes.map((c) => {
                                            return { key: c.id, text: c.name };
                                        }) : []}
                                        selectedKey={this.state.selected.intake}
                                        onChange={this._onIntakeSelected}
                                        placeholder="Pilih tahun pelajaran..."
                                        ariaLabel="Pilih tahun pelajaran" />
                                    {
                                        this.state.searchingSchedule ? <Stack horizontalAlign="baseline" styles={{ root: { marginTop: 5 } }}>
                                            <Spinner label={"Mengecek jadwal ..."} labelPosition="right" size={SpinnerSize.small} />
                                        </Stack> : null
                                    }
                                </Stack.Item>
                                <Stack.Item styles={{ root: { width: '50%' } }}>
                                    <Label required={true}>Pilih KEP</Label>
                                    <TagPicker
                                        removeButtonAriaLabel="Remove"
                                        disabled={this.state.scheduling || this.state.searchingSchedule || !this.state.selected.intake || this.state.retrieveIntakesData}
                                        onResolveSuggestions={(filterText: string) => {
                                            let { KEP } = this.state.data;
                                            if (KEP && filterText) {
                                                return KEP.filter((d) => {
                                                    return d.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 || d.id.toLowerCase().indexOf(filterText.toLowerCase()) > -1;
                                                }).map((d) => {
                                                    return { key: d.id, name: d.name, metadata: d };
                                                });
                                            } else {
                                                return [];
                                            }
                                        }}
                                        onRenderSuggestionsItem={(props: any) => {
                                            return <Stack styles={{ root: { padding: 10 } }} horizontal tokens={{ childrenGap: 10 }} verticalAlign='center'>
                                                <Text>{props.name}</Text>
                                                {props.metadata.scheduleGenerated ? <Stack styles={{ root: { borderRadius: 5, padding: "3px 6px", background: '#107c10', color: '#fff' } }}>
                                                    <Text variant='small'>Jadwal Tersedia</Text>
                                                </Stack> : null}
                                            </Stack>
                                        }}
                                        onChange={this._onSelectedKEPChanged}
                                        pickerSuggestionsProps={{
                                            suggestionsHeaderText: 'Pilihan KEP',
                                            noResultsFoundText: 'KEP tidak ditemukan',
                                        }}
                                        onEmptyInputFocus={() => {
                                            let { KEP } = this.state.data;
                                            if (KEP) {
                                                return KEP.map((d) => { return { key: d.id, name: d.name, metadata: d }; });
                                            } else { return []; }
                                        }}

                                        selectedItems={selectedKEPData ? [{ key: selectedKEPData.id, name: selectedKEPData.name }] : []}
                                        itemLimit={1} />
                                    {this.state.retrieveIntakesData ? <Spinner label='Memuat daftar KEP ...' labelPosition='right' size={SpinnerSize.small} /> : null}
                                </Stack.Item>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack.Item styles={{ root: { width: '25%' } }}>
                                    <DatePicker
                                        disabled={this.state.scheduling || !this.state.selected.intake || this.state.scheduleExist || this.state.searchingSchedule || !this.state.selected.KEP}
                                        label={"Tanggal mulai"}
                                        formatDate={this._onFormatDate}
                                        onSelectDate={this._onStartDateSelected}
                                        minDate={selectedIntakeData ? moment(selectedIntakeData.startDate).toDate() : undefined}
                                        maxDate={selectedIntakeData ? moment(selectedIntakeData.endDate).toDate() : undefined}
                                        value={this.state.selected.startDate ? moment(this.state.selected.startDate).toDate() : undefined}
                                        placeholder="Pilih tanggal mulai ..."
                                        ariaLabel="Pilih tanggal mulai" />
                                </Stack.Item>
                                <Stack.Item styles={{ root: { width: '25%' } }}>
                                    <Dropdown
                                        disabled={this.state.scheduling || !this.state.selected.intake || this.state.scheduleExist || this.state.searchingSchedule || !this.state.selected.KEP}
                                        label={"Hari"}
                                        onChange={this._onDaySelected}
                                        selectedKey={this.state.selected.day ? this.state.selected.day : undefined}
                                        options={[
                                            { key: "Senin", text: "Senin" },
                                            { key: "Selasa", text: "Selasa" },
                                            { key: "Rabu", text: "Rabu" },
                                            { key: "Kamis", text: "Kamis" },
                                            { key: "Jumat", text: "Jumat" },
                                            { key: "Sabtu", text: "Sabtu" },
                                            { key: "Minggu", text: "Minggu" }
                                        ]}
                                        placeholder="Pilih hari ..."
                                        ariaLabel="Pilih hari" />
                                </Stack.Item>
                                <Stack.Item styles={{ root: { width: '25%' } }}>
                                        <Dropdown
                                            disabled={this.state.scheduling || !this.state.selected.intake || this.state.scheduleExist || this.state.searchingSchedule || !this.state.selected.KEP}
                                            label={"Jam"}
                                            onChange={this._onHourSelected}
                                            selectedKey={this.state.selected.hour ? this.state.selected.hour : undefined}
                                            options={[
                                                { key: "01", text: "01" },
                                                { key: "02", text: "02" },
                                                { key: "03", text: "03" },
                                                { key: "04", text: "04" },
                                                { key: "05", text: "05" },
                                                { key: "06", text: "06" },
                                                { key: "07", text: "07" },
                                                { key: "08", text: "08" },
                                                { key: "09", text: "09" },
                                                { key: "10", text: "10" },
                                                { key: "11", text: "11" },
                                                { key: "12", text: "12" },
                                                { key: "13", text: "13" },
                                                { key: "14", text: "14" },
                                                { key: "15", text: "15" },
                                                { key: "16", text: "16" },
                                                { key: "17", text: "17" },
                                                { key: "18", text: "18" },
                                                { key: "19", text: "19" },
                                                { key: "20", text: "20" },
                                                { key: "21", text: "21" },
                                                { key: "22", text: "22" },
                                                { key: "23", text: "23" },
                                                { key: "24", text: "24" }
                                            ]}
                                            placeholder="Pilih jam ..."
                                            ariaLabel="Pilih jam" />
                                </Stack.Item>
                                <Stack.Item styles={{ root: { width: '25%' } }}>
                                    <Dropdown
                                        disabled={this.state.scheduling || !this.state.selected.intake || this.state.scheduleExist || this.state.searchingSchedule || !this.state.selected.KEP}
                                        label={"Menit"}
                                        onChange={this._onMinuteSelected}
                                        selectedKey={this.state.selected.minute ? this.state.selected.minute : undefined}
                                        options={[
                                            { key: "00", text: "00" },
                                            { key: "05", text: "05" },
                                            { key: "10", text: "10" },
                                            { key: "15", text: "15" },
                                            { key: "20", text: "20" },
                                            { key: "25", text: "25" },
                                            { key: "30", text: "30" },
                                            { key: "35", text: "35" },
                                            { key: "40", text: "40" },
                                            { key: "45", text: "45" },
                                            { key: "50", text: "50" },
                                            { key: "55", text: "55" }
                                        ]}
                                        placeholder="Pilih menit ..."
                                        ariaLabel="Pilih menit" />
                                </Stack.Item>
                            </Stack>
                            <Stack.Item>
                                {this.state.scheduling ? <Spinner label={"Mempersiapkan jadwal ... "} size={SpinnerSize.large} /> : null}
                            </Stack.Item>
                            {
                                this.state.showSchedules && !this.state.scheduling && this.state.schedules && this.state.selected.startDate && this.state.selected.day && this.state.selected.hour !== '' && this.state.selected.minute !== '' && selectedKEPData && selectedIntakeData ? (
                                    <Stack.Item>
                                        <ScheduleList data={this.state.data}
                                            schedules={this.state.schedules}
                                            selected={{
                                                KEP: selectedKEPData,
                                                intake: selectedIntakeData,
                                                startDate: this.state.selected.startDate,
                                                hour: this.state.selected.hour,
                                                minute: this.state.selected.minute
                                            }}
                                            onSave={this._onScheduleSaved} />
                                    </Stack.Item>
                                ) : null
                            }
                        </Stack>
                    ) : null
                }
            </Stack>
        );
    }
}
