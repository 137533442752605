import React, { RefObject, useEffect } from 'react';
import styles from './styles.module.scss';

// assets

// services

// props

// components
import { CommandBar, CommandBarButton, DatePicker, Spinner, Stack, Text } from '@fluentui/react';
import moment from 'moment';
import { IKEPDataProps, IScheduleDataProps } from '../../../props/data';
import ScheduleService from '../../../services/schedule';
import { DownloadTableExcel } from 'react-export-table-to-excel';

interface SchedulesListProps {
    text?: string;
}

type SelectedDateProps = {
    start: string;
    end: string;
}

type GroupByDateScheduleProps = {
    date: string;
    schedules: IScheduleDataProps[];
}

const SchedulesList: React.FC<SchedulesListProps> = (props: SchedulesListProps) => {
    const scheduleService = new ScheduleService();
    const tableRef: RefObject<HTMLTableElement> = React.createRef<HTMLTableElement>()

    const [selectedDate, setSelectedDate] = React.useState<SelectedDateProps>({
        start: moment().startOf('week').toISOString(),
        end: moment().endOf('week').toISOString()
    });
    const [schedules, setSchedules] = React.useState<IScheduleDataProps[]>([]);
    const [groupeByDateSchedules, setGroupByDateSchedules] = React.useState<GroupByDateScheduleProps[]>([]);
    const [loaded, setLoaded] = React.useState<boolean>(false);

    useEffect(() => {
        _onRetrieveSchedules(selectedDate);
    }, []);

    const _onRetrieveSchedules = async (date: SelectedDateProps) => {
        try {
            setLoaded(false);
            const _schedules = await scheduleService.getRange(date.start, date.end);
            let _groupByDateSchedules: GroupByDateScheduleProps[] = [];
            _schedules.sort((a, b) => (moment(a.date).format("DDMMYYYY") > moment(b.date).format("DDMMYYYY")) ? 1 : ((moment(b.date).format("DDMMYYYY") > moment(a.date).format("DDMMYYYY")) ? -1 : 0)).forEach((sch) => {
                if (sch.kep && sch.lesson) {
                    const gidx = _groupByDateSchedules.findIndex((g) => moment(g.date).format("DDMMYYYY") === moment(sch.date).format("DDMMYYYY"));
                    if (gidx < 0 && sch.kep) {
                        _groupByDateSchedules.push({
                            date: sch.date,
                            schedules: [sch]
                        })
                    } else if (sch.kep) {
                        _groupByDateSchedules[gidx].schedules.push(sch);
                    }
                }
            });
            _groupByDateSchedules = _groupByDateSchedules.sort((a, b) => (moment(a.date).format("DDMMYYYY") > moment(b.date).format("DDMMYYYY")) ? 1 : ((moment(b.date).format("DDMMYYYY") > moment(a.date).format("DDMMYYYY")) ? -1 : 0));

            setSchedules(_schedules);
            setGroupByDateSchedules(_groupByDateSchedules);
            setLoaded(true);
        } catch (e) {

        }
    }

    const renderDateSchedules = (dateSchedules: GroupByDateScheduleProps) => {
        let _groupByKEP: {
            kep: IKEPDataProps;
            schedules: IScheduleDataProps[];
        }[] = [];

        dateSchedules.schedules.forEach((sch) => {
            const gidx = _groupByKEP.findIndex((g) => g.kep.id === sch.kep?.id);
            if (gidx < 0 && sch.kep) {
                _groupByKEP.push({
                    kep: sch.kep,
                    schedules: [sch]
                })
            } else if (sch.kep) {
                _groupByKEP[gidx].schedules.push(sch);
            }
        });
        _groupByKEP = _groupByKEP.sort((a, b) => (a.kep.name > b.kep.name) ? 1 : ((b.kep.name > a.kep.name) ? -1 : 0));

        return <>
            {_groupByKEP.map((g) => {
                return <>
                    <thead>
                        <tr>
                            <th className={styles.title}>
                                <Text style={{ fontWeight: '600' }}>{g.kep.name.toUpperCase()}</Text>
                            </th>
                            <th className={styles.title}>
                                <Text style={{ fontWeight: '600' }}>{moment(dateSchedules.date).format("dddd, DD MMMM YYYY").toUpperCase()}</Text>
                            </th>
                            <th className={styles.title}>
                                <Text style={{ fontWeight: '600' }}>PK.</Text>
                            </th>
                            <th className={styles.title}>
                                <Text style={{ fontWeight: '600' }}>PENGAJAR</Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {g.schedules.map((sch) => {
                            return <tr>
                                <td className={styles.text}>
                                    {sch.lesson?.module?.class ? <Text>{sch.lesson.module.class.name}</Text> : <Text></Text>}
                                </td>
                                <td className={styles.text}>
                                    {sch.lesson ? <Text>{sch.lesson.name}</Text> : <Text></Text>}
                                </td>
                                <td className={styles.text}>
                                    <Text>{sch.hour}:{sch.minute}</Text>
                                </td>
                                <td className={styles.text}>
                                    {sch.teacher ? <Text>{sch.teacher.name}</Text> : <Text></Text>}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </>
            })}
        </>
    }

    return <Stack className={styles.container}>
        <Stack className={styles.header}>
            <h2>Lihat Jadwal Lengkap</h2>
        </Stack>
        <Stack className={styles.body} tokens={{ childrenGap: 20 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                    <DatePicker label={'Mulai Tanggal'}
                        value={moment(selectedDate.start).toDate()}
                        onSelectDate={(date) => {
                            selectedDate.start = date ? moment(date).startOf('day').toISOString() : selectedDate.start;
                            setSelectedDate(selectedDate);
                            _onRetrieveSchedules(selectedDate);
                        }} formatDate={(date) => date ? moment(date).format("DD/MM/YYYY") : ""}
                        disabled={!loaded} />
                </Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                    <DatePicker label={'Sampai Tanggal'}
                        value={moment(selectedDate.end).toDate()}
                        onSelectDate={(date) => {
                            selectedDate.end = date ? moment(date).endOf('day').toISOString() : selectedDate.end;
                            setSelectedDate(selectedDate);
                            _onRetrieveSchedules(selectedDate);
                        }} formatDate={(date) => date ? moment(date).format("DD/MM/YYYY") : ""}
                        disabled={!loaded} />
                </Stack>
            </Stack>
            <Stack tokens={{childrenGap: 20}}>
                {!loaded ? <Stack horizontal horizontalAlign={'start'}><Spinner label='Memuat jadwal ...' labelPosition='right' /></Stack> : null}
                {loaded ? <>
                    <CommandBar items={[
                        {
                            key: 'download_excel',
                            onRender: () => {
                                return <DownloadTableExcel
                                    filename={`Jadwal SEP - ${moment(selectedDate.start).format("DDMM")} - ${moment(selectedDate.end).format("DDMM")}`}
                                    sheet="users"
                                    currentTableRef={tableRef.current}
                                >
                                    <CommandBarButton disabled={groupeByDateSchedules.length < 1} iconProps={{ iconName: "Download" }} text={"Download (Excel)"} styles={{ root: { height: '100%' } }} />
                                </DownloadTableExcel>
                            }
                        }
                    ]} styles={{root: {padding: 0}}} />
                    {groupeByDateSchedules.length < 1 ? <Text>Tidak ada jadwal SEP antara {moment(selectedDate.start).format("DD/MM/YYYY")} sampai {moment(selectedDate.end).format("DD/MM/YYYY")}</Text> : null}
                    <table ref={tableRef} className={styles.table}>
                        {groupeByDateSchedules.map(renderDateSchedules)}
                    </table>
                </> : null}
            </Stack>
        </Stack>
    </Stack>
};

export default SchedulesList;
