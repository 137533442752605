import React from 'react';

// import interfaces
import { IFormFieldProps } from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';
import { Stack, TextField } from '@fluentui/react';

// import fabric ui components


interface IFETextAreaState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFETextAreaProps extends IFETextAreaState {
    onValueChanged(key: string, value: string, errorMessage?: string): void;
}

export default class FETextArea extends React.Component<IFETextAreaProps, IFETextAreaState> {
    constructor(props: IFETextAreaProps) {
        super(props);

        let { field, fields } = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props: IFETextAreaProps) {
        this.setState({ field: props.field });
    }

    private _onTextFieldChanged = (evt?: any, value?: string) => {
        let { field, fields } = this.state;
        let error = Validation.field(field, value, fields);
        this.props.onValueChanged(field.key, value || "", error);
    }

    public render() {
        let { field } = this.state;
        return (
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content} />
                </Stack.Item>
                <Stack.Item>
                    <TextField multiline={true} disabled={field.isDisabled} value={field.value || ""} onChange={this._onTextFieldChanged} />
                </Stack.Item>
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description} />
                </Stack.Item>
            </Stack>
        );
    }
}