import React from 'react';

// import services
import GeneralService from './../../../services/general';

// import components
import UserList from './../../../components/user/userList';
import { IColumn, Stack } from '@fluentui/react';

// import fabric ui


// local interfaces
interface IUserAdministratorProps { }
interface IUserAdministratorState { }

export default class UserAdministrator extends React.Component<IUserAdministratorProps, IUserAdministratorState> {
    private listColumns: IColumn[] = [
        {
            key: "userName",
            fieldName: "name",
            name: "Nama Lengkap",
            minWidth: 0
        },
        {
            key: "email",
            fieldName: "email",
            name: "Alamat Email",
            minWidth: 200
        },
        {
            key: "phoneNumber",
            fieldName: "phoneNumber",
            name: "No. Telepon",
            minWidth: 100
        },
        {
            key: "city",
            fieldName: "city",
            name: "Domisili",
            minWidth: 0
        },
        {
            key: "lessons",
            fieldName: "lessons",
            name: "Pelajaran",
            minWidth: 320,
            onRender: (item?: any) => {
                if (item && item.teacherDetails && item.teacherDetails.lessons) {
                    return (
                        <Stack>
                            {
                                item.teacherDetails.lessons.map((lesson: any) => {
                                    return <Stack.Item>{lesson.name}</Stack.Item>;
                                })
                            }
                        </Stack>
                    );
                } else { return "-"; }
            }
        }
    ];

    constructor(props: IUserAdministratorProps) {
        super(props);
    }

    public render() {
        const isUserAdmin = GeneralService.isCurrentUserAdmin();
        const isUserSecretary = GeneralService.isCurrentUserSecretary();
        return (
            <Stack styles={{ root: { marginTop: 20 } }}>
                <Stack.Item>
                    <UserList excelTitle='Daftar Sekretaria' allowCreate={isUserAdmin || isUserSecretary} allowUpdate={isUserAdmin || isUserSecretary} allowDelete={isUserAdmin} allowResetPassword={isUserAdmin} role={"Sekretariat"} listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true} />
                </Stack.Item>
            </Stack>
        );
    }
}
