import React from 'react';

// import components
import ModuleList from './../../components/module/moduleList';
import { IColumn, Stack, Text } from '@fluentui/react';

// import fabric ui

// local interfaces
interface IModulePageProps { }
interface IModulePageState { }

export default class ModulePage extends React.Component<IModulePageProps, IModulePageState> {
    private listColumns: IColumn[] = [
        {
            key: 'moduleName',
            name: 'Nama Modul',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true,
            data: 'string',
            isPadded: true
        },
        {
            key: 'keps',
            name: 'KEP Terkait',
            minWidth: 200,
            data: 'string',
            isPadded: true,
            isMultiline: true,
            onRender: (item) => {
                return <Stack tokens={{ childrenGap: 8 }} horizontal wrap>
                    {(item.includedKEPs || []).map((kep: any) => {
                        return <Stack styles={{root: {backgroundColor: '#eee', border: '1px solid #bbb', borderRadius: 5, padding: '3px 8px'}}}>
                            <Text variant={'small'}>{kep.name}</Text>
                        </Stack>;
                    })}
                </Stack>;
            }
        },
        {
            key: 'className',
            name: 'Kelas',
            minWidth: 200,
            maxWidth: 200,
            data: 'string',
            isPadded: true,
            isMultiline: true,
            onRender: (item) => {
                return <Text>{item.class.name}</Text>;
            }
        },
        {
            key: 'order',
            name: 'Urutan',
            minWidth: 50,
            maxWidth: 50,
            data: 'string',
            isPadded: true,
            isMultiline: true,
            onRender: (item) => {
                return <Text>{item.order}</Text>;
            }
        },
        {
            key: 'message',
            name: 'Catatan',
            minWidth: 250,
            maxWidth: 250,
            data: 'string',
            isMultiline: true,
            onRender: (item) => {
                return <Stack tokens={{ childrenGap: 5 }}>
                    {item.class.deleted ? <Text style={{ color: '#a20000' }}>!!! Kelas "{item.class.name}" sudah dihapus, harap segera ganti dengan kelas lain</Text> : null}
                    {(item.includedKEPs || []).map((kep: any) => {
                        if (kep.deleted) {
                            return <Text style={{ color: '#a20000' }}>!!! KEP "{kep.name}" sudah dihapus, harap segera ganti dengan kep lain</Text>;
                        } else { return null; }
                    })}
                </Stack>
            }
        }
    ]

    constructor(props: IModulePageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack styles={{ root: { padding: 20 } }}>
                <Stack.Item>
                    <h2>Modul</h2>
                </Stack.Item>
                <Stack.Item>
                    <ModuleList listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true} searchBy={["name"]} sortable={["name"]} filterable={["name"]} />
                </Stack.Item>
            </Stack>
        );
    }
}
