import { v4 as uuid } from 'uuid';
import Request from './request';
import ClassService from './class';
import KEPService from './kep';

// import interfaces
import { IModuleDataProps, IKEPDataProps, ILessonDataProps, IClassDataProps } from './../props/data';

// import sample data
import moduleData from './../sample/module';

export default class ModuleService {
    private classService: ClassService = new ClassService();
    private kepService: KEPService = new KEPService();

    public async getById(id: string, expands?: ('kep' | 'class')[]): Promise<IModuleDataProps | undefined> {
        try {
            const results: any = await Request.get(`/api/module/${id}`);
            const relatedModule: IModuleDataProps = results.data.data;

            return relatedModule;
        } catch (e) {
            throw (e);
        }
    }

    public async getAll(expands?: ('kep' | 'class')[]): Promise<IModuleDataProps[]> {
        // get all modules
        const results: any = await Request.get("/api/module");
        const modules: IModuleDataProps[] = results.data.data;

        return modules;
    }

    public async create(data: IModuleDataProps): Promise<IModuleDataProps> {
        try {
            data.id = uuid();
            let result: any = await Request.post("/api/module", { ...data, active: true });
            return result.data.data as IModuleDataProps;
        } catch (e) {
            throw (e);
        }
    }

    public async update(id: string, data: IModuleDataProps) {
        try {
            await Request.patch(`/api/module/${id}`, { ...data, id: null });
            return data;
        } catch (e) {
            throw (e);
        }
    }

    public async delete(data: IModuleDataProps) {
        try {
            await Request.delete(`/api/module/${data.id}`);
            return data;
        } catch (e) {
            throw (e);
        }
    }
}