import { v4 as uuid } from 'uuid';
import Request from './request';

// import interfaces
import { IClassDataProps } from './../props/data';

// import sample data
import classData from './../sample/class';

export default class ClassService {
    public async getById(id: string): Promise<IClassDataProps> {
        try {
            let results: any = await Request.get(`/api/kep-class/${id}`);
            let relatedClass: IClassDataProps = results.data.data;
            return relatedClass;
        } catch (e: any) {
            throw (e);
        }
    }

    public async getAll(): Promise<IClassDataProps[]> {
        try {
            let results: any = await Request.get("/api/kep-class");
            let classes: IClassDataProps[] = results.data.data;
            return classes;
        } catch (e: any) {
            throw (e);
        }
    }

    public async create(data: IClassDataProps) {
        data.id = uuid();
        let result: any = await Request.post("/api/kep-class", data);
        return result.data.data as IClassDataProps;
    }

    public async update(id: string, data: IClassDataProps) {
        try {
            await Request.patch(`/api/kep-class/${id}`, { ...data, id: null });
            return data;
        } catch (e: any) {
            throw (e);
        }
    }

    public async delete(data: IClassDataProps) {
        try {
            await Request.delete(`/api/kep-class/${data.id}`);
            return data;
        } catch (e: any) {
            throw (e);
        }
    }
}