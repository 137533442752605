import React from 'react';

// import interfaces
import { IFormFieldProps } from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';
import { Stack } from '@fluentui/react';

// import fabric ui components

interface IFECustomFieldState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFECustomFieldProps extends IFECustomFieldState {
    onValueChanged(key: string, value: string, errorMessage?: string): void;
}

export default class FETextField extends React.Component<IFECustomFieldProps, IFECustomFieldState> {
    constructor(props: IFECustomFieldProps) {
        super(props);

        let { field, fields } = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props: IFECustomFieldProps) {
        this.setState({ field: props.field });
    }

    public render() {
        let { field } = this.state;
        return (
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content} />
                </Stack.Item>
                {
                    field.onRender ? (
                        <Stack.Item>
                            {field.onRender(field)}
                        </Stack.Item>
                    ) : null
                }
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description} />
                </Stack.Item>
            </Stack>
        );
    }
}