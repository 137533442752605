import { Stack } from '@fluentui/react';
import React from 'react';

// import fabric ui

// local interfaces
interface ILogoutPageState { }
interface ILogoutPagePageProps { }

export default class LogoutPage extends React.Component<ILogoutPagePageProps, ILogoutPageState> {
    constructor(props: ILogoutPagePageProps) {
        super(props);
    }

    componentDidMount() {
        setTimeout(() => {
            window.location.href = "/login";
        }, 5000);
    }

    public render() {
        return (
            <Stack>
                Berhasil keluar dari sistem. Kami akan mengembalikan anda ke halaman login dalam beberapa saat atau klik disini untuk kembali ke halaman login.
            </Stack>
        );
    }
}
