import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';
import { Panel, PanelType, Stack, TextField } from '@fluentui/react';

// import fabric ui


// local interfaces
interface IDownloadJSONProps {
    data: any;
    onPanelClosed(): void;
}
interface IDownloadJSONState { }

export default class Vector extends React.Component<IDownloadJSONProps, IDownloadJSONState> {
    constructor(props: IDownloadJSONProps) {
        super(props);
    }

    private _onPanelClosed = () => {
        this.props.onPanelClosed();
    }

    public render() {
        return (
            <Panel
                headerText="Buat Pengguna Baru"
                type={PanelType.medium}
                isOpen={true}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                isFooterAtBottom={true}>
                <Stack horizontalAlign="baseline" styles={{ root: { marginTop: 10 } }}>
                    <TextField multiline={true} styles={{ root: { width: '100%' }, field: { height: '80vh', width: '100%' } }} value={JSON.stringify(this.props.data)} />
                </Stack>
            </Panel>
        );
    }
}
