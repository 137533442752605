import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { IModuleDataProps, ILessonDataProps } from './../../props/data';
import { IListPanelClosed } from './../../props/general';

// import services
import ModuleService from './../../services/module';
import LessonService from './../../services/lesson';
import FormMetadataService from './../../components/forms/services/metadata';
import FormValidationService from './../../components/forms/services/validation';

// import components
import Form from './../../components/forms';
import { IFormFieldProps, IFieldChoiceProps } from './../../components/forms/formProps';
import { MessageBarType, DropdownMenuItemType, Stack, Spinner, SpinnerSize, PrimaryButton, DefaultButton, Panel, MessageBar } from '@fluentui/react';

// import fabric ui

// local interfaces

interface ILessonPropsPanelProps {
    isOpen: boolean;
    data?: ILessonDataProps;
    onPanelClosed(props?: IListPanelClosed): void;
}

interface ILessonPropsPanelState {
    loaded: boolean;
    isOpen: boolean;
    saving: boolean;
    data?: ILessonDataProps;
    isUpdate: boolean;
    modules: IFieldChoiceProps[];
    formFields: IFormFieldProps[];
    messageBar?: { text: string; type: MessageBarType };
}

export default class LessonPropsPanel extends React.Component<ILessonPropsPanelProps, ILessonPropsPanelState> {
    private moduleService: ModuleService = new ModuleService();
    private lessonService: LessonService = new LessonService();
    private defaultFormFields: IFormFieldProps[] = [
        {
            promoted: true,
            key: "id",
            title: "ID",
            type: "textfield",
            isRequired: false,
            isDisabled: true,
            isHidden: true
        },
        {
            promoted: true,
            key: "name",
            title: "Nama Pelajaran",
            type: "textfield",
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "moduleId",
            title: "Modul terkait",
            type: "dropdown",
            choicesConfiguration: {
                choices: []
            },
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "chapter",
            title: "No. bab",
            type: "spinbutton",
            spintButtonConfiguration: {
                step: 1,
                min: 1,
                max: 5
            },
            isRequired: true,
            isDisabled: false,
            value: '1'
        },
        {
            promoted: true,
            key: "duration",
            title: "Lama pelajaran (minggu)",
            type: "spinbutton",
            spintButtonConfiguration: {
                step: 1,
                min: 1,
                max: 10
            },
            isRequired: true,
            isDisabled: false,
            value: "1"
        },
    ];

    constructor(props: ILessonPropsPanelProps) {
        super(props);

        this.state = {
            loaded: false,
            isOpen: false,
            saving: false,
            isUpdate: props.data ? true : false,
            data: props.data,
            modules: [],
            formFields: []
        }
    }

    private getModuleOptions(modules: IModuleDataProps[]) {
        let options: IFieldChoiceProps[] = [];
        let classes: string[] = [];
        modules.forEach((module) => {
            if (classes.indexOf(module.classId) < 0) {
                options.push({ key: module.classId, text: module.class.name, itemType: DropdownMenuItemType.Header });
                classes.push(module.classId);
            }
            options.push({ key: module.id, text: module.name });
        });

        return options;
    }

    public async componentWillMount() {
        await this.getData();
    }

    private async getData() {
        try {
            let modules = await this.moduleService.getAll();
            modules.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
            modules.sort((a, b) => (a.class.order > b.class.order) ? 1 : ((b.class.order > a.class.order) ? -1 : 0));
            let options = this.getModuleOptions(modules);

            let formFields: IFormFieldProps[] = JSON.parse(JSON.stringify(this.defaultFormFields));
            formFields.forEach((field) => {
                if (field.key === 'moduleId') {
                    field.choicesConfiguration = { choices: options };
                }
            });
            this.setState({
                loaded: true,
                modules: options,
                formFields
            });
        } catch (error) {

        }
    }

    public componentWillReceiveProps(props: ILessonPropsPanelProps) {
        let formFields: IFormFieldProps[] = JSON.parse(JSON.stringify(this.defaultFormFields));
        formFields.forEach((field) => {
            if (field.key === 'moduleId') {
                field.choicesConfiguration = { choices: this.state.modules };
            }
        });

        this.setState({
            isOpen: props.isOpen,
            isUpdate: props.data ? true : false,
            data: props.data,
            formFields
        });
    }

    private _onPanelClosed = (props?: IListPanelClosed) => {
        this.props.onPanelClosed(props);
    }

    private _onCreate = async (): Promise<void> => {
        let fieldsValidation = FormValidationService.fields(this.state.formFields);
        if (!fieldsValidation.isError) {
            try {
                this.setState({
                    saving: true,
                    formFields: this.state.formFields.map((field) => {
                        field.isDisabled = true;
                        return field;
                    })
                });

                let metadata = FormMetadataService.generate(this.state.formFields);
                let newData: ILessonDataProps = await this.lessonService.create(metadata);
                this.setState({ saving: false });
                this._onPanelClosed({
                    refreshData: true,
                    messageBar: {
                        text: `Pelajaran ${newData.name} berhasil ditambahkan`,
                        type: MessageBarType.success
                    }
                });
            } catch (e) {
                this.setState({
                    saving: false,
                    formFields: this.state.formFields.map((field) => {
                        field.isDisabled = false;
                        return field;
                    }),
                    messageBar: {
                        text: `Maaf, ada masalah saat mencoba menambahkan KEP baru. Harap coba beberapa saat lagi. Pesan error: ${e}`,
                        type: MessageBarType.error
                    }
                });
            }
        } else {
            this.setState({
                saving: false,
                formFields: fieldsValidation.fields.map((field) => {
                    field.isDisabled = false;
                    return field;
                })
            });
        }
    }

    private _onUpdate = async (): Promise<void> => {
        let fieldsValidation = FormValidationService.fields(this.state.formFields);
        if (!fieldsValidation.isError) {
            this.setState({ saving: true }); this.setState({
                saving: true,
                formFields: this.state.formFields.map((field) => {
                    field.isDisabled = true;
                    return field;
                })
            });

            let metadata = FormMetadataService.generate(this.state.formFields);
            let updatedData = await this.lessonService.update(metadata.id, metadata);
            this.setState({ saving: false });
            this._onPanelClosed({
                refreshData: true,
                messageBar: {
                    text: `Pelajaran ${updatedData.name} berhasil diubah`,
                    type: MessageBarType.success
                }
            });
        }
    }

    private renderPanelFooter = (): JSX.Element => {
        return (
            <Stack horizontalAlign="baseline">
                {
                    this.state.saving ? (
                        <Spinner label={this.state.isUpdate ? "Megubah data pelajaran ... " : "Menambahkan pelajaran baru ... "} labelPosition="right" size={SpinnerSize.medium} />
                    ) : null
                }
                {
                    !this.state.saving ? (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                {this.state.isUpdate ? <PrimaryButton label={"Ubah"} onClick={this._onUpdate}>Ubah</PrimaryButton> : null}
                                {!this.state.isUpdate ? <PrimaryButton label={"Tambahkan"} onClick={this._onCreate}>Tambahkan</PrimaryButton> : null}
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton label={"Tambahkan"} onClick={() => this._onPanelClosed()}>Batal</DefaultButton>
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
            </Stack>
        );
    }

    private _onFormFieldsChanged = (fields: IFormFieldProps[]) => {
        this.setState({ formFields: fields, data: undefined });
    }

    public render() {
        return (
            <Panel
                headerText={this.props.data ? "Ubah Data Pelajaran" : "Buat Pelajaran Baru"}
                isOpen={this.state.isOpen}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                onRenderFooterContent={this.renderPanelFooter}
                isFooterAtBottom={true}>
                {
                    !this.state.loaded ? (
                        <Spinner size={SpinnerSize.large} label="Mengambil data ..." labelPosition="right" />
                    ) : null
                }
                {
                    this.state.messageBar ? (
                        <Stack.Item>
                            <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => { this.setState({ messageBar: undefined }) }}>
                                {this.state.messageBar.text}
                            </MessageBar>
                        </Stack.Item>
                    ) : null
                }
                {
                    this.state.loaded ? (
                        <Form fields={this.state.formFields} onValueChanged={this._onFormFieldsChanged} defaultValue={this.state.data} />
                    ) : null
                }
            </Panel>
        );
    }
}
