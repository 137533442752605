import React from 'react';

// import interfaces
import { IFormFieldProps } from './../formProps';

// import subcomponents
import FieldHeader from './../sub/fieldHeader';
import FieldFooter from './../sub/fieldFooter';

// import services
import Validation from './../services/validation';
import { IDropdownOption, Text, DropdownMenuItemType, ISelectableOption, Stack, Dropdown } from '@fluentui/react';

// import fabric ui components


interface IFEDropdownState {
    field: IFormFieldProps;
    fields: IFormFieldProps[];
}

interface IFEDropdownProps extends IFEDropdownState {
    onValueChanged(key: string, value?: string | number, errorMessage?: string): void;
}

export default class FEDropdown extends React.Component<IFEDropdownProps, IFEDropdownState> {
    constructor(props: IFEDropdownProps) {
        super(props);

        let { field, fields } = this.props;
        this.state = {
            field,
            fields
        }
    }

    public componentWillReceiveProps(props: IFEDropdownProps) {
        this.setState({ field: props.field });
    }

    private isAllSelected(field: IFormFieldProps): boolean {
        let value = field.value || [];
        let choices = field.choicesConfiguration && field.choicesConfiguration.choices ? field.choicesConfiguration.choices : [];
        let isAllSelected: boolean = true;
        choices.forEach((choice) => {
            if (value.indexOf(choice.key) < 0) {
                isAllSelected = false;
            }
        });

        return isAllSelected;
    }

    private _onDropdownChanged = (evt?: any, option?: IDropdownOption) => {
        let { field, fields } = this.state;
        let value = field.value || [];
        if (option && option.key === 'selectall') {
            let isAllSelected = this.isAllSelected(field);
            if (isAllSelected) {
                value = [];
            } else {
                let choices = field.choicesConfiguration && field.choicesConfiguration.choices ? field.choicesConfiguration.choices : [];
                value = choices.filter((choice) => { return choice.key != "selectall" && !choice.itemType }).filter((choice) => { return choice.itemType !== DropdownMenuItemType.Header }).map((choice) => { return choice.key; });
            }
        } else {
            if (option && value.indexOf(option.key) > -1) {
                value.splice(value.indexOf(option.key), 1);
            } else if (option) {
                value.push(option.key)
            }
        }
        let error = Validation.field(field, value, fields);
        this.props.onValueChanged(field.key, value, error);
    }

    private renderOption = (option?: ISelectableOption): JSX.Element | null => {
        let { field } = this.state;
        if (option) {
            if (option.itemType === DropdownMenuItemType.Header) {
                return <Text style={{ fontWeight: 600 }}>{option.text}</Text>
            } else if (option.key === 'selectall') {
                return <Text>{option.text}</Text>
            } else if (field.choicesConfiguration && field.choicesConfiguration.onRenderOption) {
                return field.choicesConfiguration.onRenderOption(option);
            } else {
                return <Text>{option.text}</Text>
            }
        } else { return null; }
    }

    public render() {
        let { field } = this.state;
        let options: IDropdownOption[] = [];
        let isAllSelected = this.isAllSelected(field);

        if (field.choicesConfiguration) {
            let choices = field.choicesConfiguration.choices || [];
            options = choices.map((choice) => {
                return { key: choice.key, text: choice.text, itemType: choice.itemType };
            });
            options.unshift({ key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider });
            if (!isAllSelected) {
                options.unshift({ key: "selectall", text: "Pilih semua" });
            } else {
                options.unshift({ key: "selectall", text: "Hapus semua" });
            }
        }

        return (
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack.Item>
                    <FieldHeader title={field.title} required={field.isRequired} content={field.content} />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown multiSelect={true}
                        disabled={field.isDisabled}
                        options={options}
                        onChange={this._onDropdownChanged}
                        selectedKeys={field.value}
                        onRenderOption={this.renderOption}
                        styles={field.choicesConfiguration ? field.choicesConfiguration.styles : undefined} />
                </Stack.Item>
                <Stack.Item>
                    <FieldFooter error={field.errorMessage} description={field.description} />
                </Stack.Item>
            </Stack>
        );
    }
}