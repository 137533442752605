import React from 'react';
import cities from './../../props/city';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { IKEPDataProps } from './../../props/data';
import { IListPanelClosed } from './../../props/general';

// import components
import Form from './../../components/forms/index';
import { IFormFieldProps } from './../../components/forms/formProps';

// import services
import KEPService from './../../services/kep';
import FormMetadataService from './../../components/forms/services/metadata';
import FormValidationService from './../../components/forms/services/validation';
import { MessageBarType, Stack, Spinner, SpinnerSize, PrimaryButton, DefaultButton, Panel, MessageBar } from '@fluentui/react';

// import fabric ui


// local interfaces

interface IKEPListProps {
    isOpen: boolean;
    data?: IKEPDataProps;
    onPanelClosed(props?: IListPanelClosed): void;
}

interface IKEPListState {
    messageBar?: { text: string; type: MessageBarType };
    isOpen: boolean;
    saving: boolean;
    data?: IKEPDataProps;
    isUpdate: boolean;
    formFields: IFormFieldProps[];
}

export default class KEPPropsPanel extends React.Component<IKEPListProps, IKEPListState> {
    private KEPService: KEPService = new KEPService();
    private defaultFormFields: IFormFieldProps[] = [
        {
            promoted: true,
            key: "id",
            title: "ID",
            type: "textfield",
            isRequired: false,
            isDisabled: true,
            isHidden: true
        },
        {
            promoted: true,
            key: "name",
            title: "Nama KEP",
            type: "textfield",
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "city",
            title: "Kota",
            type: "dropdown",
            choicesConfiguration: {
                choices: cities
            },
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "address",
            title: "Alamat",
            type: "textarea",
            isRequired: false,
            isDisabled: false
        }
    ];

    constructor(props: IKEPListProps) {
        super(props);

        this.state = {
            isOpen: false,
            saving: false,
            isUpdate: props.data ? true : false,
            data: props.data,
            formFields: JSON.parse(JSON.stringify(this.defaultFormFields))
        }
    }

    public componentWillReceiveProps(props: IKEPListProps) {
        this.setState({
            formFields: JSON.parse(JSON.stringify(this.defaultFormFields)),
            isOpen: props.isOpen,
            isUpdate: props.data ? true : false,
            data: props.data
        });
    }

    private _onPanelClosed = (props?: IListPanelClosed) => {
        this.props.onPanelClosed(props);
    }

    private _onCreate = async (): Promise<void> => {
        let fieldsValidation = FormValidationService.fields(this.state.formFields);
        if (!fieldsValidation.isError) {
            try {
                this.setState({
                    saving: true,
                    formFields: this.state.formFields.map((field) => {
                        field.isDisabled = true;
                        return field;
                    })
                });

                let metadata = FormMetadataService.generate(this.state.formFields);
                let newData = await this.KEPService.create(metadata);
                this.setState({ saving: false });
                this._onPanelClosed({
                    refreshData: true,
                    messageBar: {
                        text: `KEP ${newData.name} berhasil ditambahkan`,
                        type: MessageBarType.success
                    }
                });
            } catch (e: any) {
                this.setState({
                    saving: false,
                    formFields: this.state.formFields.map((field) => {
                        field.isDisabled = false;
                        return field;
                    }),
                    messageBar: {
                        text: `Maaf, ada masalah saat mencoba menambahkan KEP baru. Harap coba beberapa saat lagi. Pesan error: ${e}`,
                        type: MessageBarType.error
                    }
                });
            }
        } else {
            this.setState({
                saving: false,
                formFields: fieldsValidation.fields.map((field) => {
                    field.isDisabled = false;
                    return field;
                })
            });
        }
    }

    private _onUpdate = async (): Promise<void> => {
        let fieldsValidation = FormValidationService.fields(this.state.formFields);
        if (!fieldsValidation.isError) {
            this.setState({ saving: true }); this.setState({
                saving: true,
                formFields: this.state.formFields.map((field) => {
                    field.isDisabled = true;
                    return field;
                })
            });

            let metadata = FormMetadataService.generate(this.state.formFields);
            let updatedData = await this.KEPService.update(metadata.id, metadata);
            this.setState({ saving: false });
            this._onPanelClosed({
                refreshData: true,
                messageBar: {
                    text: `KEP ${updatedData.name} berhasil diubah`,
                    type: MessageBarType.success
                }
            });
        }
    }

    private _onFormFieldsChanged = (fields: IFormFieldProps[]) => {
        this.setState({ formFields: fields, data: undefined });
    }

    private renderPanelFooter = (): JSX.Element => {
        return (
            <Stack horizontalAlign="baseline">
                {
                    this.state.saving ? (
                        <Spinner label={this.state.isUpdate ? "Mengubah data KEP ... " : "Menambahkan KEP baru ... "} labelPosition="right" size={SpinnerSize.medium} />
                    ) : null
                }
                {
                    !this.state.saving ? (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                {this.state.isUpdate ? <PrimaryButton label={"Ubah"} onClick={this._onUpdate}>Ubah</PrimaryButton> : null}
                                {!this.state.isUpdate ? <PrimaryButton label={"Tambahkan"} onClick={this._onCreate}>Tambahkan</PrimaryButton> : null}
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton label={"Tambahkan"} onClick={() => this._onPanelClosed()}>Batal</DefaultButton>
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
            </Stack>
        );
    }

    public render() {
        return (
            <Panel
                headerText={this.props.data ? "Ubah Data KEP" : "Buat KEP Baru"}
                isOpen={this.state.isOpen}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                onRenderFooterContent={this.renderPanelFooter}
                isFooterAtBottom={true}>
                <Stack className={kepInternalStyles.form}>
                    {
                        this.state.messageBar ? (
                            <Stack.Item>
                                <MessageBar messageBarType={this.state.messageBar.type} isMultiline={true} onDismiss={() => { this.setState({ messageBar: undefined }) }}>
                                    {this.state.messageBar.text}
                                </MessageBar>
                            </Stack.Item>
                        ) : null
                    }
                    <Form fields={this.state.formFields} onValueChanged={this._onFormFieldsChanged} defaultValue={this.state.data} />
                </Stack>
            </Panel>
        );
    }
}
