import { v4 as uuid } from 'uuid';
import Request from './request';

// import interfaces
import { ITeacherLessonConnectionProps } from './../props/data';

// import sample data
import teacherLessonConnection from './../sample/teacherLessonConnection';

// import services
import LessonService from './lesson';
import UserService from './user';

export default class TeacherLessonConnectionService {
    private lessonService: LessonService = new LessonService();

    public async getByTeacherId(teacherId: string): Promise<ITeacherLessonConnectionProps[]> {
        try {
            let results: any = await Request.get(`/api/teacher/${teacherId}/lessons`);
            let lessons = await this.lessonService.getAll();

            let data: ITeacherLessonConnectionProps[] = results.data.data.map((d: any) => {
                let connection = d.connection as ITeacherLessonConnectionProps;
                connection.lesson = lessons.find((lesson) => { return lesson.id === connection.lessonId; });
                return connection;
            });
            return data;
        } catch (e: any) {
            throw (e.toString());
        }
    }

    public async getByLessonId(lessonId: string): Promise<ITeacherLessonConnectionProps[]> {
        await new Promise<void>((resolve) => { setTimeout(() => { return resolve(); }, 1000); });
        return teacherLessonConnection.filter((conn) => { return conn.lessonId === lessonId; });
    }

    public async create(data: ITeacherLessonConnectionProps): Promise<ITeacherLessonConnectionProps> {
        try {
            data.id = uuid();
            let result: any = await Request.post("/api/lesson-teacher", data);
            return result.data.data as ITeacherLessonConnectionProps;
        } catch (e: any) {
            throw (e.toString());
        }
    }

    public async delete(data: ITeacherLessonConnectionProps | { id: string }) {
        try {
            await Request.delete(`/api/lesson-teacher/${data.id}`);
            return data;
        } catch (e: any) {
            throw (e.toString());
        }
    }

    public async update(id: string, data: ITeacherLessonConnectionProps): Promise<ITeacherLessonConnectionProps> {
        try {
            await Request.patch(`/api/lesson-teacher/${id}`, data);
            return data;
        } catch (e: any) {
            throw (e.toString());
        }
    }
}