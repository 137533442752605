import React from 'react';

// import styles
import kepInternalStyles from './../../styles/kepinternal.module.scss';

// import interfaces
import { IModuleDataProps, IClassDataProps, IKEPDataProps } from './../../props/data';
import { IListPanelClosed } from './../../props/general';

// import components
import Form from './../../components/forms/index';
import { IFormFieldProps, IFieldChoiceProps } from './../../components/forms/formProps';

// import services
import ModuleService from './../../services/module';
import ClassService from './../../services/class';
import KEPService from './../../services/kep';
import FormMetadataService from './../../components/forms/services/metadata';
import FormValidationService from './../../components/forms/services/validation';

// import fabric ui

import generalService from '../../services/general';
import { DropdownMenuItemType, MessageBarType, Stack, Spinner, SpinnerSize, PrimaryButton, DefaultButton, Panel, Text } from '@fluentui/react';

// local interfaces

interface IModulePropsPanelProps {
    isOpen: boolean;
    data?: IModuleDataProps;
    onPanelClosed(props?: IListPanelClosed): void;
}

interface IModulePropsPanelState {
    loaded: boolean;
    isOpen: boolean;
    saving: boolean;
    data?: IModuleDataProps;
    isUpdate: boolean;
    classes: IClassDataProps[];
    kep: IKEPDataProps[];
    formFields: IFormFieldProps[];
    error?: string;
}

export default class ModulePropsPanel extends React.Component<IModulePropsPanelProps, IModulePropsPanelState> {
    private moduleService: ModuleService = new ModuleService();
    private classService: ClassService = new ClassService();
    private kepService: KEPService = new KEPService();

    private defaultFormFields: IFormFieldProps[] = [
        {
            promoted: true,
            key: "id",
            title: "ID",
            type: "textfield",
            isRequired: false,
            isDisabled: true,
            isHidden: true
        },
        {
            promoted: true,
            key: "name",
            title: "Nama modul",
            type: "textfield",
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "classId",
            title: "Kelas terkait",
            type: "dropdown",
            choicesConfiguration: {
                choices: []
            },
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "includedKEPsId",
            title: "KEP Terkait",
            type: "multiselectdropdown",
            choicesConfiguration: {
                choices: []
            },
            isRequired: true,
            isDisabled: false
        },
        {
            promoted: true,
            key: "order",
            title: "Urutan",
            type: "number",
            isRequired: true,
            isDisabled: false
        }
    ]

    constructor(props: IModulePropsPanelProps) {
        super(props);

        this.state = {
            loaded: false,
            isOpen: false,
            saving: false,
            isUpdate: props.data ? true : false,
            data: props.data,
            classes: [],
            kep: [],
            formFields: []
        }
    }

    private getKepChoices(kep: IKEPDataProps[]): IFieldChoiceProps[] {
        let kepUniqueCity: string[] = generalService.getUniqueFieldValues(kep, 'city');
        kepUniqueCity = kepUniqueCity.sort();
        let kepChoices: IFieldChoiceProps[] = [];
        kepUniqueCity.forEach((city) => {
            let relatedKEP = kep.filter((kep) => { return kep.city.toLowerCase() === city.toLowerCase(); });
            if (relatedKEP.length > 0) {
                kepChoices.push({ key: city, text: city, itemType: DropdownMenuItemType.Header });
                relatedKEP.forEach((kep) => { kepChoices.push({ key: kep.id, text: kep.name }); });
            }
        });
        return kepChoices;
    }

    public async componentWillMount() {
        let classes = await this.classService.getAll();
        let kep = await this.kepService.getAll();

        let formFields: IFormFieldProps[] = JSON.parse(JSON.stringify(this.defaultFormFields));
        formFields.forEach((field) => {
            if (field.key === 'classId') {
                field.choicesConfiguration = { choices: classes.map((classProps) => { return { key: classProps.id, text: classProps.name } }) };
            } else if (field.key === 'includedKEPsId') {
                field.choicesConfiguration = { choices: this.getKepChoices(kep) };
                field.value = kep.map((kep) => { return kep.id });
            }
        });

        this.setState({
            loaded: true,
            classes,
            kep,
            formFields,
            data: this.props.data
        });
    }

    public componentWillReceiveProps(props: IModulePropsPanelProps) {
        let formFields: IFormFieldProps[] = JSON.parse(JSON.stringify(this.defaultFormFields));
        formFields.forEach((field) => {
            if (field.key === 'classId') {
                field.choicesConfiguration = { choices: this.state.classes.map((classProps) => { return { key: classProps.id, text: classProps.name } }) };
            } else if (field.key === 'includedKEPsId') {
                field.choicesConfiguration = { choices: this.getKepChoices(this.state.kep) };
                field.value = this.state.kep.map((kep) => { return kep.id });
            }
        });

        this.setState({
            isOpen: props.isOpen,
            isUpdate: props.data ? true : false,
            data: props.data,
            formFields
        });
    }

    private _onPanelClosed = (props?: IListPanelClosed) => {
        this.props.onPanelClosed(props);
    }

    private _onCreate = async (): Promise<void> => {
        try {
            let fieldsValidation = FormValidationService.fields(this.state.formFields);
            if (!fieldsValidation.isError) {
                this.setState({
                    error: undefined,
                    saving: true,
                    formFields: this.state.formFields.map((field) => {
                        field.isDisabled = true;
                        return field;
                    })
                });

                let metadata = FormMetadataService.generate(this.state.formFields);
                let newData = await this.moduleService.create(metadata);
                this.setState({ saving: false });
                this._onPanelClosed({
                    refreshData: true,
                    messageBar: {
                        text: `Modul ${newData.name} berhasil ditambahkan`,
                        type: MessageBarType.success
                    }
                });
            } else {
                this.setState({
                    error: "Pastikan semua data telah terisi dengan benar",
                    saving: false,
                    formFields: fieldsValidation.fields.map((field) => {
                        field.isDisabled = false;
                        return field;
                    })
                });
            }
        } catch (e) {
            this.setState({ saving: false, error: "Maaf, terjadi kesalahan. Harap coba beberapa saat lagi." });
        }
    }

    private _onUpdate = async (): Promise<void> => {
        try {
            let fieldsValidation = FormValidationService.fields(this.state.formFields);
            if (!fieldsValidation.isError) {
                this.setState({
                    error: undefined,
                    saving: true,
                    formFields: this.state.formFields.map((field) => {
                        field.isDisabled = true;
                        return field;
                    })
                });

                let metadata = FormMetadataService.generate(this.state.formFields);
                let updatedData = await this.moduleService.update(metadata.id, metadata);
                this.setState({ saving: false });
                this._onPanelClosed({
                    refreshData: true,
                    messageBar: {
                        text: `Modul ${updatedData.name} berhasil diubah`,
                        type: MessageBarType.success
                    }
                });
            } else {
                this.setState({
                    error: "Pastikan semua data telah terisi dengan benar",
                    saving: false,
                    formFields: fieldsValidation.fields.map((field) => {
                        field.isDisabled = false;
                        return field;
                    })
                });
            }
        } catch (e) {
            this.setState({ saving: false, error: "Maaf, terjadi kesalahan. Harap coba beberapa saat lagi." });
        }
    }

    private _onFormFieldsChanged = (fields: IFormFieldProps[]) => {
        this.setState({ formFields: fields, data: undefined });
    }

    private renderPanelFooter = (): JSX.Element => {
        return (
            <Stack horizontalAlign="baseline">
                {
                    this.state.saving ? (
                        <Spinner label={this.state.isUpdate ? "Megubah data modul ... " : "Menambahkan modul baru ... "} labelPosition="right" size={SpinnerSize.medium} />
                    ) : null
                }
                {
                    !this.state.saving ? (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                {this.state.isUpdate ? <PrimaryButton label={"Ubah"} onClick={this._onUpdate}>Ubah</PrimaryButton> : null}
                                {!this.state.isUpdate ? <PrimaryButton label={"Tambahkan"} onClick={this._onCreate}>Tambahkan</PrimaryButton> : null}
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton label={"Tambahkan"} onClick={() => this._onPanelClosed()}>Batal</DefaultButton>
                            </Stack.Item>
                        </Stack>
                    ) : null
                }
            </Stack>
        );
    }

    public render() {
        return (
            <Panel
                headerText={this.props.data ? "Ubah Data Modul" : "Buat Modul Baru"}
                isOpen={this.state.isOpen}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                onRenderFooterContent={this.renderPanelFooter}
                isFooterAtBottom={true}>
                {
                    !this.state.loaded ? (
                        <Spinner size={SpinnerSize.large} label="Mengambil data ..." labelPosition="right" />
                    ) : null
                }
                {this.state.error ? <Text>{this.state.error}</Text> : null}
                {
                    this.state.loaded ? (
                        <Form fields={this.state.formFields} onValueChanged={this._onFormFieldsChanged} defaultValue={this.state.data} />
                    ) : null
                }
            </Panel>
        );
    }
}
