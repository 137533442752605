import React from 'react';

// import services
import GeneralService from './../../../services/general';

// import components
import UserList from './../../../components/user/userList';
import { IColumn, Stack, Text } from '@fluentui/react';

// import fabric ui


// local interfaces
interface IUserAdministratorProps { }
interface IUserAdministratorState { }

export default class UserAdministrator extends React.Component<IUserAdministratorProps, IUserAdministratorState> {
    private listColumns: IColumn[] = [
        {
            key: "userName",
            fieldName: "name",
            name: "Nama Lengkap",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true
        },
        {
            key: "email",
            fieldName: "email",
            name: "Alamat Email",
            minWidth: 200,
            maxWidth: 200,
            isMultiline: true
        },
        {
            key: "phoneNumber",
            fieldName: "phoneNumber",
            name: "No. Telepon",
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true
        },
        {
            key: "city",
            fieldName: "city",
            name: "Domisili",
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true
        },
        {
            key: 'keps',
            name: 'Dapat Mengajar di KEP',
            minWidth: 200,
            isMultiline: true,
            onRender: (item) => {
                return <Stack tokens={{ childrenGap: 8 }} horizontal wrap>
                    {(item.includedKEPs || []).map((kep: any) => {
                        return <Stack styles={{root: {backgroundColor: '#eee', border: '1px solid #bbb', borderRadius: 5, padding: '3px 8px'}}}>
                            <Text variant={'small'}>{kep.name}</Text>
                        </Stack>;
                    })}
                </Stack>;
            }
        }
    ];

    constructor(props: IUserAdministratorProps) {
        super(props);
    }

    public render() {
        const isUserAdminOrSecretary = GeneralService.isCurrentUserAdmin() || GeneralService.isCurrentUserSecretary();
        return (
            <Stack styles={{ root: { marginTop: 20 } }}>
                <Stack.Item>
                    <UserList excelTitle='Daftar Guru' allowCreate={isUserAdminOrSecretary} allowUpdate={isUserAdminOrSecretary} allowDelete={isUserAdminOrSecretary} allowResetPassword={isUserAdminOrSecretary} role={"Guru"} listColumns={this.listColumns} includeCommandBar={true} hasActionButton={true} />
                </Stack.Item>
            </Stack>
        );
    }
}
