import React, { Component, ReactNode } from 'react';
import kepInternalStyles from './../../styles/kepinternal.module.scss';
import { IListPanelClosed } from './../../props/general';
import { IColumn, Panel, Stack, Spinner, SpinnerSize, PrimaryButton, DefaultButton, DetailsList, SelectionMode, DetailsListLayoutMode, Text } from '@fluentui/react';

export interface IDeleteDataPanelOnDeleteReturn {
    success: boolean;
    message?: string;
    delay?: number;
}

export interface IDeleteDataPanelProps {
    isOpen: boolean;
    data: any; // Tipe data yang lebih spesifik sesuai dengan data yang digunakan
    columns?: IColumn[];
    onPanelClosed(props?: IListPanelClosed): void;
    onDelete(data: any): Promise<IDeleteDataPanelOnDeleteReturn>;
}

interface IDeleteDataPanelState {
    isOpen: boolean;
    data: any;
    loading?: boolean;
    deleted?: boolean;
}

class DeleteDataPanel extends Component<IDeleteDataPanelProps, IDeleteDataPanelState> {
    constructor(props: IDeleteDataPanelProps) {
        super(props);

        this.state = {
            isOpen: false,
            data: props.data
        };
    }

    public componentWillReceiveProps(props: IDeleteDataPanelProps) {
        this.setState({
            isOpen: props.isOpen,
            data: props.data,
            loading: false,
            deleted: false
        });
    }

    private _onPanelClosed = (props?: IListPanelClosed) => {
        this.props.onPanelClosed(props);
    }

    private async _onDelete() {
        this.setState({
            loading: true
        });

        try {
            await this.props.onDelete(this.state.data);
            this.setState({
                loading: false,
                deleted: true
            });
        } catch (error) {
            // Tangani pengecualian dengan lebih baik
            console.error("Error during delete:", error);
        }
    }

    public render(): ReactNode {
        return (
            <Panel
                headerText="Menghapus Data"
                isOpen={this.state.isOpen || false}
                onDismiss={() => this._onPanelClosed()}
                closeButtonAriaLabel="Tutup"
                onRenderFooterContent={() => {
                    let allDeleted = (this.props.data.filter((d: any) => d.status || (d.status && d.status !== 'completed' && d.status !== 'error'))).length > 0;
                    return (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {this.state.loading ? <Spinner size={SpinnerSize.medium} label="Menghapus data ..." labelPosition="right" /> : null}
                            {!this.state.loading && !this.state.deleted && !allDeleted ? <PrimaryButton onClick={() => { this._onDelete() }}>Hapus</PrimaryButton> : null}
                            {!this.state.loading && !this.state.deleted && !allDeleted ? <DefaultButton onClick={() => { this._onPanelClosed() }}>Batal</DefaultButton> : null}
                            {!this.state.loading && allDeleted ? <DefaultButton onClick={() => { this._onPanelClosed() }}>Tutup</DefaultButton> : null}
                        </Stack>
                    );
                }}
                isFooterAtBottom={true}>
                <Stack className={kepInternalStyles.form}>
                    <Stack.Item>
                        <Text>Apakah anda yakin untuk menghapus semua kelas dibawah ini?</Text>
                    </Stack.Item>
                    <DetailsList
                        items={this.props.data}
                        compact={false}
                        columns={this.props.columns}
                        selectionMode={SelectionMode.none}
                        setKey="none"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true} />
                </Stack>
            </Panel>
        );
    }
}

export default DeleteDataPanel;
