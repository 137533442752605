import React from 'react';

// assets

// services

// props

// components
import { Dialog, Spinner, SpinnerSize } from '@fluentui/react';

interface LoadingDialogProps {
    text?: string;
}

const LoadingDialog: React.FC<LoadingDialogProps> = (props: LoadingDialogProps) => {
    return <Dialog
        hidden={false}
        onDismiss={() => { }}
        dialogContentProps={{}}
        modalProps={{isBlocking: true}} >
        <Spinner size={SpinnerSize.large} label={props.text} />
    </Dialog>
};

export default LoadingDialog;
