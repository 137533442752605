import { v4 as uuid } from 'uuid';
import moment from 'moment';
import Request from './request';

// import interfaces
import { ISaveScheduleParameterProps, IScheduleDataProps } from './../props/data';

// import sample data
import schedule from './../sample/schedule';
import kep from './../sample/kep';
import lesson from './../sample/lesson';
import user from './../sample/user';
import event from './../sample/event';

export default class ScheduleService {
    public async getAll(): Promise<IScheduleDataProps[]> {
        await new Promise<void>((resolve) => { setTimeout(() => { return resolve(); }, 2000); });
        return schedule;
    }

    public async getIntakeByKEPId(kepId: string, intakeId: string): Promise<IScheduleDataProps[]> {
        try {
            let results: any = await Request.get(`/api/schedule?kep=${kepId}&intake=${intakeId}`);
            let schedules: IScheduleDataProps[] = results.data.data;
            return schedules;
        } catch (e: any) {
            throw (e);
        }
    }

    public async getRangeByKEPId(kepId: string, startDate: string, endDate: string): Promise<IScheduleDataProps[]> {
        try {
            let results: any = await Request.get(`/api/schedule?kep=${kepId}&startDate=${startDate}&endDate=${endDate}`);
            let schedules: IScheduleDataProps[] = results.data.data;
            return schedules;
        } catch (e: any) {
            throw (e);
        }
    }

    public async getRangeByTeacherId(teacherId: string, startDate: string, endDate: string): Promise<IScheduleDataProps[]> {
        /*await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        return schedule.filter((sch) => {
            let scheduleDate = moment(sch.date);
            let rangeStartDate = moment(startDate);
            let rangeEndDate = moment(endDate);
            
            return (
                sch.teacherId === teacherId && 
                moment.duration(scheduleDate.diff(rangeStartDate)).asSeconds() >= 0 && 
                moment.duration(rangeEndDate.diff(scheduleDate)).asSeconds() >= 0
            );
        });*/
        try {
            let results: any = await Request.get(`/api/schedule?teacher=${teacherId}&startDate=${startDate}&endDate=${endDate}`);
            let schedules: IScheduleDataProps[] = results.data.data;
            return schedules;
        } catch (e: any) {
            throw (e);
        }
    }

    public async getRange(startDate: string, endDate: string): Promise<IScheduleDataProps[]> {
        /*await new Promise((resolve) => {setTimeout(() => {return resolve();}, 2000);});
        return schedule.filter((sch) => {
            let scheduleDate = moment(sch.date);
            let rangeStartDate = moment(startDate);
            let rangeEndDate = moment(endDate);
            
            return (
                sch.teacherId === teacherId && 
                moment.duration(scheduleDate.diff(rangeStartDate)).asSeconds() >= 0 && 
                moment.duration(rangeEndDate.diff(scheduleDate)).asSeconds() >= 0
            );
        });*/
        try {
            let results: any = await Request.get(`/api/schedule?startDate=${startDate}&endDate=${endDate}`);
            let schedules: IScheduleDataProps[] = results.data.data;
            return schedules;
        } catch (e: any) {
            throw (e);
        }
    }

    public async create(data: IScheduleDataProps): Promise<IScheduleDataProps> {
        try {
            data.id = uuid();
            await Request.post("/api/schedule", data);
            return data;
        } catch (e: any) {
            throw (e.toString());
        }
    }

    public async update(data: IScheduleDataProps): Promise<IScheduleDataProps> {
        try {
            await Request.patch(`/api/schedule/${data.id}`, data);
            return data;
        } catch (e: any) {
            throw (e.toString());
        }
    }

    public async save(schedules: ISaveScheduleParameterProps[]): Promise<IScheduleDataProps[]> {
        let error = undefined;
        let results: IScheduleDataProps[] = [];
        let updateSchedules = schedules.filter((schedule) => {
            return schedule.id && schedule.touched;
        });
        let newSchedules = schedules.filter((schedule) => {
            return !schedule.id || (schedule.id && schedule.id === "");
        });

        for (var ctr = 0; ctr < updateSchedules.length; ctr++) {
            let schedule = updateSchedules[ctr];
            try {
                let result = await this.update(schedule as IScheduleDataProps);
                results.push(result);
            } catch (e: any) {
                results.push(schedule);
            }
        }

        for (var ctr = 0; ctr < newSchedules.length; ctr++) {
            let schedule = newSchedules[ctr];
            try {
                let result = await this.create(schedule as IScheduleDataProps);
                results.push(result);
            } catch (e: any) {
                results.push(schedule);
            }
        }

        return results;
    }

    public async deleteById(data: IScheduleDataProps) {
        try {
            await Request.delete(`/api/schedule/${data.id}`);
            return data;
        } catch (e: any) {
            throw (e.toString());
        }
    }

    public async deleteByKEPId(KEPId: string, intakeId: string) {
        try {
            await Request.delete(`/api/schedule/kep/${KEPId}/intake/${intakeId}`);
            return {};
        } catch (e: any) {
            throw (e.toString());
        }
    }

    public async getTeacherScheduleByDateRange(teacherId: string, startDate: string, endDate: string) {
        try {
            await new Promise<void>((resolve) => { setTimeout(() => { return resolve(); }, 300); });
            return schedule.filter((d) => {
                return d.teacherId ? d.teacherId.toLowerCase() === teacherId.toLowerCase() && startDate < d.date && d.date < endDate : false;
            });
        } catch (e: any) {
            throw (e.toString());
        }
    }

    public async getScheduleByIntake(intakeId: string, KEPId: string): Promise<IScheduleDataProps[]> {
        try {
            let schedules = await Request.delete(`/api/schedule/kep/${KEPId}/intake/${intakeId}`);
            return schedules.data.data;
        } catch (e: any) {
            throw (e.toString());
        }
    }
}