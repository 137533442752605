import React from 'react';

// import fabric ui


// import user pivots
import UserAdministrator from './pivots/administrator';
import UserTeacher from './pivots/teacher';
import UserSecretary from './pivots/secretary';
import { Stack, Pivot, PivotItem } from '@fluentui/react';

// local interfaces
interface IUserPageProps { }
interface IUserPageState { }

export default class ModulePage extends React.Component<IUserPageProps, IUserPageState> {
    constructor(props: IUserPageProps) {
        super(props);
    }

    public render() {
        return (
            <Stack styles={{ root: { padding: 20 } }}>
                <Stack.Item>
                    <h2>Pengguna</h2>
                </Stack.Item>
                <Stack.Item>
                    <Pivot aria-label="Basic Pivot Example">
                        <PivotItem headerText="Administrator">
                            <UserAdministrator />
                        </PivotItem>
                        <PivotItem headerText="Guru">
                            <UserTeacher />
                        </PivotItem>
                        <PivotItem headerText="Sekretariat">
                            <UserSecretary />
                        </PivotItem>
                    </Pivot>
                </Stack.Item>
            </Stack>
        );
    }
}
