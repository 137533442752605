import React, { createRef } from 'react';

// import assets
import backgroundImage from './../../assets/images/login-background.jpg';
import logo from './../../assets/images/logo-no-border.png';

// import styles
import loginPageStyles from './login.module.scss';

// import services
import AuthenticationService from '../../services/authentication';
import { ITextField, Stack, MessageBar, MessageBarType, TextField, PrimaryButton, Spinner, SpinnerSize, Text } from '@fluentui/react';

// import fabric ui

// local interfaces
interface ILoginPageState {
    loadingLogin?: boolean;
    errorMessage?: string;
    loginSuccessful?: boolean;
    username: string;
    password: string;
}

interface ILoginPageProps { }

export default class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {
    private usernameField: ITextField | null = null;
    private authenticationService: AuthenticationService = new AuthenticationService();

    constructor(props: ILoginPageProps) {
        super(props);

        this.state = {
            username: "",
            password: ""
        }
    }

    public componentDidMount() {
        if (this.usernameField) { this.usernameField.focus(); }
    }

    private _onUsernameChanged = (e?: any, value?: string): void => {
        this.setState({ username: value || "" });
    }

    private _onPasswordChanged = (e?: any, value?: string): void => {
        this.setState({ password: value || "" });
    }

    private _onPasswordKeyDown = (e?: any): void => {
        if (e.keyCode === 13) { this._onLogin(); }
    }

    private _onLogin = async () => {
        try {
            let error = false;
            if (this.state.username.trim() === "") {
                error = true;
                this.setState({ errorMessage: "Email tidak boleh kosong", loadingLogin: false });
            } else if (this.state.password.trim() === "") {
                error = true;
                this.setState({ errorMessage: "Password tidak boleh kosong", loadingLogin: false });
            }

            if (!error) {
                this.setState({ errorMessage: undefined, loadingLogin: true });
                let { username, password } = this.state;
                await this.authenticationService.login(username, password);
                this.setState({ errorMessage: undefined, loginSuccessful: true });
                setTimeout(() => {
                    window.location.href = '/beranda';
                }, 4000);
            }
        } catch (e: any) {
            this.setState({ errorMessage: e ? e.message || e : "Maaf, ada kelasahan pada sistem. Harap coba beberapa saat lagi. Apabila masih mengalami masalah, kontak bagian administrasi.", loadingLogin: false });
        }
    }

    /*public render() {
        return (
            <Stack horizontal className={loginPageStyles.loginPage}>
                <Stack.Item align="center" className={loginPageStyles.middleContainer}>
                    <Stack>
                        <Stack.Item className={loginPageStyles.header}>
                            <Text as={"h2"} variant="xLargePlus">Login</Text>
                        </Stack.Item>
                        {
                            this.state.loginSuccessful ? (
                                <Stack.Item>
                                    <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
                                        Login berhasil! Kami akan membawa anda ke halaman utama dalam beberapa saat.
                                    </MessageBar>
                                </Stack.Item>
                            ) : null
                        }
                        {
                            this.state.errorMessage ? (
                                <Stack.Item>
                                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                        {this.state.errorMessage || ""}
                                    </MessageBar>
                                </Stack.Item>
                            ) : null
                        }
                        <Stack.Item>
                            <TextField label="Email" onChange={this._onUsernameChanged} disabled={this.state.loadingLogin || this.state.loginSuccessful} />
                            <TextField type="password" label="Sandi" onChange={this._onPasswordChanged} disabled={this.state.loadingLogin || this.state.loginSuccessful} />
                        </Stack.Item>
                        {
                            !this.state.loginSuccessful ? (
                                <Stack.Item className={loginPageStyles.footer} align="start">
                                    {
                                        !this.state.loadingLogin ? (
                                            <PrimaryButton onClick={this._onLogin}>Masuk</PrimaryButton>
                                        ) : null
                                    }
                                    {this.state.loadingLogin ? <Spinner size={SpinnerSize.medium} label={"Harap tunggu ..."} labelPosition="right" /> : null}
                                </Stack.Item>
                            ) : null
                        }
                    </Stack>
                </Stack.Item>
            </Stack>
        );
    }*/

    public render() {
        return (
            <Stack horizontal className={loginPageStyles.loginPage} styles={{ root: { background: `url(${backgroundImage})` } }}>
                <Stack horizontalAlign={"center"} verticalAlign={"center"} className={loginPageStyles.formSide}>
                    <Stack className={loginPageStyles.container} tokens={{ childrenGap: 20 }}>
                        {/* form */}
                        <Stack verticalAlign={"center"} horizontal tokens={{ childrenGap: 15 }}>
                            <img src={logo} style={{ width: 50 }} />
                            <Stack.Item>
                                <Text variant={"xLarge"}>SEP ST. Yohanes Penginjil</Text>
                            </Stack.Item>
                        </Stack>
                        {
                            this.state.loginSuccessful ? (
                                <Stack.Item>
                                    <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
                                        Login berhasil! Kami akan membawa anda ke halaman utama dalam beberapa saat.
                                    </MessageBar>
                                </Stack.Item>
                            ) : null
                        }
                        {
                            this.state.errorMessage ? (
                                <Stack.Item>
                                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                        {this.state.errorMessage || ""}
                                    </MessageBar>
                                </Stack.Item>
                            ) : null
                        }
                        <Stack styles={{ root: { width: '100%' } }} tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                <TextField componentRef={(fieldRef) => { this.usernameField = fieldRef; }} label="Email" onChange={this._onUsernameChanged} disabled={this.state.loadingLogin || this.state.loginSuccessful} />
                            </Stack.Item>
                            <Stack.Item>
                                <TextField type="password" label="Sandi" onChange={this._onPasswordChanged} onKeyDown={this._onPasswordKeyDown} disabled={this.state.loadingLogin || this.state.loginSuccessful} />
                            </Stack.Item>
                        </Stack>
                        <Stack.Item>
                            {
                                !this.state.loadingLogin ? (
                                    <PrimaryButton onClick={this._onLogin}>Masuk</PrimaryButton>
                                ) : null
                            }
                            {this.state.loadingLogin ? <Spinner size={SpinnerSize.medium} label={"Harap tunggu ..."} labelPosition="right" /> : null}
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
        );
    }
}
